 @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

 body,
 html {
     margin: 0;
     padding: 0;
     font-family: "Roboto", sans-serif;
     font-weight: 400;
     font-style: normal;
 }

 .kioskApp .slidescreen {
     width: 100%;
     height: 100vh;
     overflow: hidden;
     display: block;
 }

 .kioskApp img {
     max-width: 100% !important
 }

 .kioskApp .slider-container {
     width: 80%;
     margin: auto;
     overflow: hidden;
     position: relative;
 }

 .kioskApp .slides {
     display: flex;
     transition: transform 0.5s ease-in-out;
 }

 .kioskApp .slide {
     min-width: 100%;
 }

 .kioskApp .prev,
 .kioskApp .next {
     cursor: pointer;
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     padding: 10px;
     background-color: rgba(0, 0, 0, 0.5);
     color: white;
     z-index: 100;
 }

 .kioskApp .prev {
     left: 0;
 }

 .kioskApp .next {
     right: 0;
 }

 .kioskApp .slide_blk {
     height: calc(100vh);
     padding: 0;
     position: relative;
     overflow: hidden;
 }

 .kioskApp .school_img {
     background-size: 100% 100%;
     padding: 10px;
     margin-bottom: 69px;
     background: linear-gradient(0deg, #ffa034, #f8c555);
     border-radius: 9px;
     width: 95% !important;
     margin: 40px auto 60px;
 }

 .kioskApp .logo-kiosk {
     padding: 1vw;
     background: linear-gradient(0deg, #ffa034, #f8c555);
     margin-bottom: 3vw;
 }

 .logo-kiosk img {
     width: 28vw !important;
     margin: 0vh auto 1vh;
     display: block; 
 }
 .kioskApp .title-bar img {
    height: 4vw !important;
    margin: auto;
    width: auto !important;
}
.kioskApp .slide1 .slide_blk:before {
     content: "";
     width: 50vw; 
     position: absolute;
     left: 0;
     top: 0; 
 }

 .kioskApp .slide1 .slide_blk:after {
     content: "";
     width: 70vw;
     height: 35vh; 
     background-size: 100% 100%;
     position: absolute;
     right: -17vh;
     bottom: -10vh;
     filter: drop-shadow(1px 1px 13px #ddd);
 }

 .kioskApp .title img {
     margin: auto;
     width: 6vw !important;
     display: inline !important;
     vertical-align: middle;
 }


 .kioskApp h3 {
     margin: auto;
     display: block;
     width: max-content;
     vertical-align: middle;
     font-size: 24px !important;
     text-align: center;
     text-transform: uppercase;
 }
 .kioskApp .screen-title {
    padding: 2vw;
}
 
.kioskApp .title h3 span {
     color: #c30c0c;
 }

 .kioskApp .slide1 .slide_blk p {
     margin-bottom: 1vh;
     font-size: 15px;
     text-align: center;
     line-height: 1.8;
 }

 .kioskApp .item ul{
    list-style-type: none;
 }

 .kioskApp img.bandstrip {
     width: 50% !important;
     margin: auto;
     display: block;
 }

 .kioskApp .item,.kioskApp 
 .demo {
     display: block;
     height: 100vh;
 }

 .kioskApp .topper-blk:before {
     content: "";
     width: 100vw;
     height: 100vh;
     background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/topper-top.png) ; 
     position: absolute;
     left: 0;
     top: -69px; 
     background-size: 100% 50%;
    background-repeat: no-repeat;
 }

 .kioskApp .topper-blk:after {
     content: "";
     width: 100vw;
     height: 100%;
     background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/topper-btm.png) no-repeat;
     background-size: 100% auto;
     position: absolute;
     right: 0;
     bottom: 0;
     background-position: bottom; 
     z-index: -1;
 }

 .kioskApp .topper-blk {
     position: relative;
     height: 100vh;
     width: 99vw;
     margin: 0;
     padding: 0 0px;
     overflow: hidden;
 }

 .kioskApp .topper-blk .logo-kiosk img {
     width: 23vw !important;
 }

 .kioskApp .ach-img {
     background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/acheiver.png);
     background-size: 100% 100%;
     border-radius: 50%;
     overflow: hidden; 
     object-position: center;
     width: 12vh !important; 
     margin-right: 1vw;
     padding: 3vw 2vw;
 }
 .slidescreen.kioskApp .carousel-indicators {
    display: none;
}
 .kioskApp .birthdayContent h3 {
     font-size: 22px !important;
     text-align: left;
     margin: 0;
     margin-bottom: 0.5vh;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 1;
     overflow: hidden;
     width: 100%;
 }

 .kioskApp .topper-blk>div {
     position: relative;
     width: 95%;
     margin: auto;
 }

 .kioskApp .topper-blk .logo-kiosk {
     background: #ddddddd4;
     margin-top: 15px;
     border-radius: 19px;
     width: max-content;
     margin: 15px auto;
     padding: 0 20px;
     margin-bottom: 4vh;
 }

 .kioskApp .topper-blk .logo-kiosk img {
     filter: unset;
 }

 .kioskApp .ach-cont p {
     font-size: 18px;
     line-height: 23px;
     margin-bottom: 0;
     margin-top: 6px;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 2;
     overflow: hidden;
 }

 .kioskApp .scrollitem {
     display: flex;
     width: 50%;
     align-items: center;
     margin-bottom: 30px;
 }

 .kioskApp .scrollgroup {
     display: flex;
     flex-wrap: wrap;
     width: 92%;
     margin: auto;
 }

 .kioskApp .ach-img img { 
     object-fit: cover;
     width: 100% !important;
     height: 8vh;
     background: #c1b7b700;
     border-radius: 50%;
     border: 5px solid #bd8f23;
 }

 .kioskApp .title {
     margin-bottom: 40px;
 }

 .kioskApp .scrollitem>div {
     width: 100%;
 }

 .kioskApp .title-bar {
     margin-bottom: 2vh;
 }

 .kioskApp .title-bar h3 {
     font-weight: normal;
 }

 .kioskApp .toppers {
     justify-content: space-between;
 }

 .kioskApp .toppers .scrollitem {
     width: 28%;
     flex-direction: column;
 }

 .kioskApp .toppers .scrollitem .ach-img img {
     width: 22vw !important;
     height: 16vw;
     margin: auto;
     display: block;
 }

 .kioskApp .scrollgroup.toppers .ach-img {
     padding-top: 2vh;
     padding-bottom: 2vh;
     margin: 0;
     width: auto !important;
     background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/toppers.png);
     background-size: 100% 100%;
     padding: 3vw 4vw;
 }

 .kioskApp .toppers p {
     font-size: 30px;
     color: #c19329;
     text-align: center;
     margin-bottom: 0;
     font-weight: 800;
 }
 

 .kioskApp .d-flex {
     display: flex;
 }

 .kioskApp .birthdayImg {
     width: 38%;
     border-radius: 50%;
     position: relative;
 }

 .kioskApp .birthdayImg img {
    width: 100% !important;
    object-fit: cover;
    border-radius: 10px;
    height: 7vw;
    border: 0.2vw solid #fda73a;
 }

 .kioskApp .birthday-block>.d-flex {
     width: 50%;
     margin-top: 6vh;
     align-items: center;
     margin-bottom: 1vh;
 }

 .kioskApp .birthday-block {
     display: flex;
     flex-wrap: wrap;
     width: 95%;
     margin: auto;
 }

 .kioskApp .birthdayContent {
    width: 65%;
    padding-left: 1.5vw;
 }

 .kioskApp .birthdayContent p {
     font-size: 16px;
     margin: 0;
 }

 .kioskApp .lines img {
    margin: auto;
    display: block;
    width: 19% !important;
}

.kioskApp .birthday-msg p {
     font-size: 32px;
     width: 80%;
     margin: auto;
 }

 .kioskApp .birthday-msg {
     width: 70%;
     margin: auto;
     background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/msg-frame.png);
     background-size: 100% 100%;
     padding: 8vw 0vw 6vw;
     margin-top: 2vh;
 }

 .kioskApp .birthday-msg p span {
     display: block;
     text-align: right;
 }

 .kioskApp .bth-gif {
    margin: auto;
    display: block;
    width: 16vw !important;
    margin-top: 0;
    margin-bottom: 50px;
 }

 .kioskApp .birthdayImg:before {
     content: "";
     width: 30% !important;
     height: 30%;
     position: absolute;
     top: -17px;
     left: -9px;
     background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/stu-brt.png);
     background-size: 100% 100%;
 }

 .kioskApp .techer-birthday .birthdayImg:before {

     background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/tech-brt.png);
     background-size: 100% 100%;
 }

 .kioskApp .gallery-section {
     background: #ddd;
     padding: 30px 0px;
     min-height: 100vh;
     background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/wall.jpg);
     background-size: auto 100%;
     background-position: left;
     position: relative;
     overflow: hidden;
 }

 .kioskApp .kiosk-gallery-blk .gallery-block {
     width: 23%;
     background: linear-gradient(0deg, #ffa034, #f8c555);
     padding: 5px;
     margin: 8px;
     box-shadow: 0px 0px 5px 0px #00000063;
     border-radius: 8px;
 }
 .kioskApp .d-flex.justify-content-center{
    flex-wrap: wrap;
 }

 .kioskApp .kiosk-gallery-blk.main .gallery-block img {
    height: 95vh;
 }
 .kioskApp .kiosk-gallery-blk .gallery-block img {
     height: 27vh;
     width: 100%;
     object-fit: contain;
     background: #ddd;
 }
 .kioskApp .kiosk-gallery-blk.main .gallery-block {
    width: 90%;
    background: linear-gradient(0deg, #ffa034, #f8c555);
    padding: 5px;
    margin: auto;
    box-shadow: 0px 0px 5px 0px #00000063;
    border-radius: 8px;
}
.container-fluid.scrollbox.wid-100 {
    width: 100%;
}
 .kiosk-activity-block h2.text-center {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #00000075;
    width: 100%;
    padding: 5px;
    font-size: 35px;
    color: #fff;
}

.kiosk-activity-block {
    position: relative;
}

 .kioskApp .kiosk-gallery-blk .gallery-block h3, 
 .kioskApp .homenews h3 {
     font-size: 20px !important;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 2; 
     width: 100%;
     overflow: hidden;
     text-align: center;
     background: #fff;
 }
 .kioskApp .galleryContent {
    padding: 13px;
    background: #fff;
    min-height: 80px;
}
.kioskApp .carousel.slide.kiosk-gallery-blk.carousel.slide {
     margin-bottom: 20px;
 }

 .kioskApp .homenews h3 {
     text-align: left;
 }

 .kioskApp .homenews p {
     font-size: 18px;
 }

 .kioskApp .news marquee {
     height: 58vh;
 }

 .kioskApp .activity.kiosk-gallery-blk {
     margin-top: 2vh;
 }

 .kioskApp .gallery-shade {
     background: #ffffffbf;
     padding: 20px 40px;
     border-radius: 13px;
     width: 90%;
     margin: auto;
     height: 90vh;
     position: relative;
     box-shadow: 0px 0px 19px 0px #a0a0a0;
 }

 .kioskApp img.img-plant {
     position: absolute;
     bottom: -60px;
     right: -80px;
     width: 15vw !important;
 }
 .kioskApp .news {
    width: 80%;
    margin: auto;
}
.kioskApp img.img-plant-left {
    position: absolute;
    bottom: -60px;
    left: -80px;
    width: 15vw !important;
 }

 .kioskApp .gallery-section:before {
     content: "";
     width: 100%;
     height: 100%;
     position: absolute;
     background: #ffffffc2;
     top: 0;
     left: 0;
 }

 .kioskApp .gallery-section .logo-kiosk {
     width: max-content;
     margin: auto;
 }
 .kioskApp .owl-carousel .owl-item li{
    list-style-type: none;
 }
 .kioskApp .gal-logo { 
     width: max-content;
     margin: auto;
     padding: 5px 10px;
     border-radius: 13px;
     margin-bottom: 0px;
 }

 .kioskApp .gal-logo img {
     margin: auto;
     display: block;
 }

 .kioskApp .topper-blk.wrapper div#carouselExampleSlidesOnly {
     margin-bottom: 1vh;
 }