 :root {
   --dark-gradient: linear-gradient(0deg, #0B5073, #27AAED);
   --light-gradient: linear-gradient(0deg, #962734, #FC4A60);
 }

 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   font-family: "Bebas Neue", sans-serif;
 }

 body,
 html {
   font-family: "Open Sans", sans-serif;
   overflow-x: hidden;

 }

 .nav-link {
   cursor: pointer;
 }

 .campuslogo.headersocialicn {
   right: 3px;
   top: 310px;
   display: flex;
   flex-direction: column;
 }

 .campuslogo.headersocialicn a {
   margin-bottom: 10px;
 }

 a {
   text-decoration: none;
   color: inherit;
 }








 
 .bodybg {
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/bodyimg.png);
   background-size: 100% 120%;
   background-repeat: no-repeat;
 }







.I-notice a {color: #fff;}
.I-notice {position: fixed;right: 0;z-index: 9;text-align: center;color: #fff;top:420px; }
@keyframes bounce1 {
  0% {
      background-color: #295d85;
  }

  33% {
      background-color: #dc3545;
  }

  66% {
      background-color: #295d85;
  }

  100% {
      background-color: #dc3545;
  }
}
.blinkbg {border-radius: 25px 0px 0px; color: #fff;font-size: 14px; font-weight: 700; -webkit-animation: colorchange 20s infinite alternate;z-index: 99;text-align: center;animation: bounce1 1s infinite;-moz-animation: bounce1 1s infinite; -webkit-animation: bounce1 1s infinite;padding: 12px;}
.blinkbg i {
  font-size: 20px;
  padding-right: 7px;
}





 .highlights marquee p {
   display: inline-block;
   text-transform: capitalize;
   margin: 0px 10px;
   color: #333;
 }

 .header {
   display: flex;
   align-items: center;
   justify-content: space-between;
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/background.gif);
   background-size: 100% auto;
   height: 105px;
 }

 .header:before {
   position: absolute;
   content: ' ';
   width: 100%;
   height: 105px;
   top: 0;
   left: 0;
   opacity: 0.6;
   background: #fff;
 }

 .campuslogo {
   position: fixed;
   right: 10px;
   bottom: 10px;
   z-index: 99;
 }

 .highlights {
   color: #fff;
   background: #00000040;
   width: 95%;
   padding-top: 14px;
 }

 .highlights i {
   color: #333;
 }

 .highlights p {
   margin-bottom: 0;
 }

 .logo {
   width: 30%;
   padding: 6px;
   position: relative;
 }

 .header-nav {
   width: 69%;
 }

 nav.navbar {
   background: linear-gradient(0deg, #0b5073, #27aaed);
   padding: 15px;
   width: 95%;
   border-radius: 30px 30px 30px 30px;
   float: left;
   margin-top: 11px;
   position: relative;
   z-index: 9;
 }

 nav.navbar ul.navbar-nav {
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   width: 100%;
 }

 .nav-link {
   color: #fff !important;
   text-transform: uppercase;
   font-weight: 400;
   font-size: 15px;
 }

 nav.navbar ul.navbar-nav li {
   text-transform: capitalize;
   font-size: 18px;
 }

 .button {
   display: block;
   margin: 0 auto;
   background: var(--dark-gradient);
   color: #fff;
   border: 0px;
   box-shadow: inset 0 0 7px 0px #3388ed;
   padding: 5px 20px;
   border-radius: 15px;
   text-transform: uppercase;

 }

 .slidersecfst {
   width: 25%;
   background: #fffbca;
   position: relative;
 }

 .slidersecfst:after {
   position: absolute;
   content: ' ';
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/news-bg.png);
   background-size: 100% 100%;
   width: 100%;
   height: 100%;
   top: 0;
   left: 35px;
   z-index: 1;
 }

 .slidersecfst:before {
   position: absolute;
   content: ' ';
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/newsbg1.png);
   background-size: contain;
   width: 100%;
   height: 160px;
   left: 0;
   z-index: 9;
   bottom: 40px;
   background-repeat: no-repeat;
 }

 .newsbg .title>div p {
   color: #FF8800;
   text-align: left;
   font-size: 16px;
   font-weight: bold;
   letter-spacing: 1.2px;
 }

 .newsbg .title>div {
   color: #0E5C83;
   display: flex;
   flex-direction: column;
   margin-left: 30px;
 }

 .slidersecscd {
   width: 100%;
 }

 .slidersec {
   display: flex;
 }

 .about-news button.button {
   margin: 0;
 }

 a:hover {
   color: unset;
 }

 .logo img {
   margin: auto;
   display: block;
 }

 .aboutmidsec {
   padding: 0 50px;
   z-index: 2;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: -40px;
   position: relative;
   width: 100%;
 }

 .aboutmidsec .phone img {
   width: 50px;
 }

 .aboutmidsec .phone {
   padding: 10px;
   background: var(--dark-gradient);
   border-radius: 20px;
   margin: 0px 25px 0px 25px;
 }

 .aboutmidsec p {
   margin-bottom: 0;
   font-size: 15px;
 }

 .aboutmidsec .button {
   width: 14%;
   padding: 20px 10px;
   text-align: center;
   text-transform: uppercase;
 }

 .padded-sec {
   padding: 0 100px;
 }

 .about-bg {
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/abt-shape1.png);
   background-size: 100%;
   background-repeat: no-repeat;
   padding: 160px 0 60px 0px;
 }

 .center-img {
   margin: auto;
   display: block;
   position: relative;
   width: max-content;
   padding: 20px;
   z-index: 9;
   width: 58%;
 }

 .center-img:after {
   content: "";
   width: 100%;
   height: 100%;
   position: absolute;
   left: 0;
   top: 0;
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/abt-shape2.png);
   transform: scale(1.2);
   background-size: 100% 100%;
   background-repeat: no-repeat;
 }

 .center-img img {
   position: relative;
   width: 100%;
   padding: 45px;
 }

 .aboutsec {
   margin-top: -52px;
 }

 .title {
   text-transform: uppercase;
   text-align: center;
   color: #10345E;
 }

 .title p {
   margin-bottom: 2px;
   color: #333333;
 }

 .about-news {
   width: 73%;
   margin: auto;
   margin-left: auto;
   margin-right: auto;
   background: #ffffffb3;
   padding: 50px 50px 0;
   border-radius: 70px;
   box-shadow: 0px 0px 10px 0px #ababab;
   margin-top: 0%;
 }


 .tabs-img img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   position: relative;
   z-index: 9;
   border-radius: 50%;
 }

 .tabs-img {
   width: 150px;
   margin: 20px auto;
   border-radius: 50%;
   height: 150px;
   position: relative;
 }

 .tab-title {
   text-align: center;
   color: #10345E;
   font-family: "Bebas Neue", sans-serif;
   font-size: 22px;
   z-index: 9;
   position: relative;

 }

 .tab:after {
   position: absolute;
   top: 120%;
   left: 50%;
   transform: translate(-50%);
   content: "";
   width: 0;
   height: 0;
   border-bottom: 20px solid #eeeeee;
   border-left: 30px solid #0000;
   border-right: 30px solid transparent;
   transition: 0.5s;
   opacity: 0;
 }

 .tab.active:after {
   top: 100%;
   opacity: 1;
 }

 .content p {
   margin: 0;
 }

 .tabs-content button.button {
   position: relative;
   bottom: -42px;
   position: relative;
 }

 .content {
   position: relative;
 }

 .header-text h3 span {
   -webkit-text-stroke: 1px #0788B1;
   color: #fbfeff;
   font-size: 32px;
 }

 .header-text h3 {
   padding: 0px 7px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   color: #666;
   text-align: center;
   padding-top: 4px;
   font-weight: normal;
   text-transform: uppercase;
   letter-spacing: 1px;
 }

 .calender {
   position: relative;
   padding: 50px 0px;
 }

 .header-text h5 {
   display: none;
   font-size: 16px;
   text-align: center;
   color: #333;
   margin: 10px;
 }

 .calender_block {
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/calender-bg.png);
   background-size: 100% 100%;
   padding: 10px 10px 60px 10px;
 }

 .sidebar-title {
   padding: 10px;
   display: none;
 }

 .event-mark {
   width: 5px;
   height: 5px;
   background-color: teal;
   border-radius: 100px;
   position: absolute;
   left: 46%;
   top: 70%;
 }

 .calendar-wrapper {
   display: flex;
   width: 100%;
   margin: auto;
   align-items: center;
 }

 .content-wrapper {
   width: 100%;
 }

 .main-wrapper {
   width: 100%;
 }

 .calendar-wrapper .emptyForm {
   height: 70px;
   overflow-y: scroll;
   margin-right: 9px;
   padding-left: 20px;
   display: none;
 }

 .calendar-footer h4 {
   margin: 20px auto;
 }

 .calendar-header button.prev-button,
 .calendar-header button.next-button {
   display: none;
 }

 .eventlist p {
   margin: 0px 9px;
 }


 .sidebar-wrapper {
   color: #fff;
   background-color: #5a649c !important;

   font-weight: 300;
   padding: 0 0 20px;
 }

 .eventCard {
   background-color: #fff;
   color: #000;
   padding: 12px 24px;
   border-bottom: 1px solid #E5E5E5;
   white-space: nowrap;
   position: relative;
   animation: slideInDown .5s;
 }

 .eventCard-header {
   font-weight: 700;
 }

 .eventCard-description {
   color: grey;
 }

 .eventCard-mark-wrapper {
   position: absolute;
   right: 0;
   top: 0;
   height: 100%;
   width: 60px;
   background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 25%, white 100%);
 }

 .eventCard-mark {
   width: 8px;
   height: 8px;
   background-color: #b39ddb;
   border-radius: 100px;
   position: absolute;
   left: 50%;
   top: 45%;
 }

 #table-body .row .col {
   padding: .6rem;
   width: 14%;
 }

 #table-body .row {
   margin-bottom: 0;
 }

 #calendar-table {
   text-align: center;
   padding: 5px 20px 0px 20px;
   margin-top: 20px;
 }

 .prev-button {
   position: absolute;
   cursor: pointer;
   left: 0;
   color: #333 !important;
 }

 .prev-button i {
   font-size: 4em;
 }

 .next-button {
   position: absolute;
   cursor: pointer;
   right: 0;
   color: #333 !important;
 }

 .next-button i {
   font-size: 4em;
 }

 #table-body .col {
   max-width: 13%;
   min-width: 14%;
   padding: 12px 0;
   text-align: center;
 }

 div#emptyForm span {
   color: #0788B1;
   min-width: 28px;
   display: inline-block;
   font-weight: 600;
 }

 .schoolnotice {
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/noticebg.png);
   background-size: 100% 100%;
   padding: 50px 0px;
 }

 .schoolnotice .newsbg {
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolnotice.png);
   background-size: 100% 100%;
   width: 90%;
   margin-left: 0;
   padding-top: 40px;
 }

 .schoolnotice .maintitle p {
   margin-left: 0;
 }

 div#emptyForm p {
   margin-bottom: 16px;
 }

 .message-container {
   display: flex;
 }

 .msgleft {
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/message-bg.png);
   background-size: 100% 100%;
 }

 .msgright {
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/message-bg1.png);
   background-size: 100% 100%;
 }

 .message-container .msgright .messageimg {
   right: auto;
   left: -80px;
 }

 .message-container .messagebox {
   width: 80%;
   position: relative;
   padding: 70px;
   margin-right: 20px;
 }

 .messagedesc {
   padding-right: 75px;
 }

 .msgright .messagedesc {
   padding-right: 0;
   padding-left: 80px;
 }

 .messagebox.msgright {
   right: 0;
   width: 100%;
   margin-left: 100px;
 }

 .message-container .messageimg {
   margin-top: 50px;
   position: absolute;
   right: -90px;
 }

 .event-cal {
   margin-top: 20px;
 }

 .event {
   padding: 140px 0px 100px 0px;
   position: relative;
 }

 .message-container .messageimg .mainbtn.button {

   width: max-content;
   margin: auto;
   padding: 11px 20px;
   border-radius: 10px;
 }

 .messageboximg p span {
   display: block;
   color: #333;
 }

 .messageboximg {
   text-transform: uppercase;
   color: #0E5C83;
   font-family: 'Bebas Neue';
   letter-spacing: 1.2px;
   font-size: 16px;
 }

 .message-container .messageimg img {
   margin: auto;
   display: block;
   width: 100%;
   border: 7px solid #fff;
   height: 203px;
   position: relative;
   z-index: 2;
   margin-bottom: 20px;
 }

 .testimonialimg {
   width: 200px;
   position: relative;
 }

 .messagebox.msgright .testimonialimg:before {
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/managerbg.png) no-repeat center bottom;

 }

 .messagedesc .maintitle {
   text-align: center;
   color: #0E5C83;
 }

 .testimonialimg:before {
   position: absolute;
   bottom: -18px;
   left: 7px;
   content: ' ';
   background-size: contain;
   height: 100%;
   width: 100%;
   z-index: 1;
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/principalbg.png) no-repeat center bottom;

 }

 .topper_bg {
   position: relative;
   padding-top: 50px;
 }

 .msgright .messageboximg {
   text-align: right;
 }

 .topper_bg:before {
   position: absolute;
   content: ' ';
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/topperbg1.png);
   background-size: contain;
   background-repeat: no-repeat;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
 }

 .topper_bg:after {
   position: absolute;
   content: ' ';
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/topperbg2.png) right bottom;

   background-repeat: no-repeat;
   width: 100%;
   height: 100%;
   right: 0;
   top: 0;
 }

 .toppertaj {
   width: 190px;
   height: 190px;
   padding: 24px 10px;
   margin: 0 auto;
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/topper-shape.png);
   background-size: cover;
 }

 .toppersec .toppertaj img {
   padding: 19px;
   margin-top: 10px;
 }

 .birthdaybg .toppertaj {
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/birthdaybg.png);
   background-size: 100% 100%;
   padding: 13px 24px 24px;
   width: 190px;
   height: 170px;
   margin: 0 auto;
 }

 .topper-block {
   width: 90%;
   margin: 40px 0;
   position: relative;
   z-index: 1;
 }

 .wid-100 {
   width: 100%;
   margin-top: -5px
 }

 img.img-fluid.birthday_icon_new {
   width: 220px;
 }

 .homeeventbox img {
   height: 220px;
   object-fit: contain;
   width: 100%;
   border-radius: 17px;
 }

 .chng_topper .toppertaj img {
   border-radius: 0;
   object-fit: contain;
   padding: 0;
   background: #ffeed9;
 }

 .toppertaj img {
   width: 100%;
   height: 140px;
   padding: 10px;
   object-fit: cover;
   border-radius: 50%;
   margin: auto;
 }

 .topperdiv {
   text-align: center;
 }

 .topperdiv.toppersec {
   position: relative;
   padding-bottom: 50px;
 }

 .topperdiv.toppersec p.per {
   position: absolute;
   opacity: 0;
   text-align: center;
   margin: 0 auto;
   display: block;
   left: 50%;
   transform: translate(-50%, -50%);
   bottom: 70px;
   transition: all 0.5s ease-in;
   font-weight: bold;
   color: #0E5C83;
   font-size: 16px;
 }

 .topperdiv.toppersec:hover p.per {
   bottom: 0;
   opacity: 1;
 }

 .topperdiv .topperdesc p.name {
   overflow: hidden;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   color: #fff;
   padding: 5px 0px;
   line-height: 30px;
   margin-bottom: 0px;
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/topperrib.png);
   background-size: 100% 100%;
 }

 .birthdaybg p.name {
   background: none !important;
   color: #333 !important;
   padding: 0 !important;
   line-height: 18px !important;
 }

 .topperdiv .topperdesc p.per {
   font-weight: bold;
   color: #0E5C83;
   font-size: 16px;
   margin-bottom: 0;
 }

 .galleryimgbox {
   position: relative;
   border: 10px solid #fff;
   box-shadow: 2px 20px 20px -18px #000;
   width: 96%;
   margin: 25px 0;
   overflow: hidden;
 }

 .topper-block .arrrow-btn {
   position: absolute;
   right: 0;
   top: 0;
 }

 .topper-block .title>img {
   margin-top: -60px;
   width: 255px;
 }

 .topper_bg .maintitle div {
   display: flex;
   flex-direction: row;
 }

 .topper_bg .maintitle p {
   margin-left: 0;
 }

 .topper_bg .maintitle div img {
   width: 83px;
   margin-top: -20px;
 }

 .maintitle>a img {
   position: absolute;
   right: 0px;
   top: 0;
 }

 .topper_bg .maintitle div>div {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-right: 20px;
 }

 .galleryimgbox .gallerydesc {
   width: 100%;
   position: absolute;
   left: 0;
   top: 0;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   opacity: 0;
   transition: 1s;
 }

 .galleryimgbox:hover .gallerydesc {
   opacity: 1;
 }

 .homegallery>div {
   padding: 110px 0 20px 0px;
   text-align: center;
 }

 .galleryimgbox h6 {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   background: linear-gradient(180deg, #ff8800, 56%, #f0dd4d);
   color: #333;
   position: absolute;
   bottom: 6px;
   margin-bottom: 0;
   width: 95%;
   text-align: center;
   font-size: 16px;
   line-height: 30px;
   border-radius: 7px;
   font-family: "Open Sans", sans-serif;
   padding: 0;
 }

 a.gallery_button,
 .dark-button {
   background: #0B5073;
   width: max-content;
   color: #fff;
   padding: 8px 19px;
   border-radius: 20px;
   border: 0px;
   text-transform: uppercase;
 }

 .footer {
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/footer-bg.png);
   background-size: 100% 100%;
   padding: 50px 80px 10px;
   position: relative;
   z-index: 1;
 }

 .footer:before {
   position: absolute;
   content: ' ';
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   opacity: 0.2;
   background: #fff;
   z-index: -1;
 }

 .copyright {
   text-align: center;
 }

 ul.socialMedia li img {
   width: 33px;
 }

 .quick-link ul {
   display: flex;
   flex-direction: column;
   height: 170px;
   flex-wrap: wrap;
   list-style: none;
   padding: 0;
 }

 .quick-link ul li {
   margin-bottom: 7px;
   font-size: 14px;
   color: #333;
   font-weight: 400;

 }

 .address p {
   display: flex;
   align-items: center;
 }

 .address p img {
   margin-right: 10px;
 }

 .ftr-logo img {
   width: auto;
   margin-bottom: 10px;
   margin-top: -21px;
   margin-left: 10px;
   display: block;
 }

 .footer iframe {
   height: 220px;
   border: 6px solid #fff;
   box-shadow: 0px 0px 5px 0px #ddd;
 }

 ul.socialMedia {
   list-style: none;
   padding: 0;
   display: flex;
   align-items: end;
   justify-content: center;
   margin-bottom: 15px;

 }

 .sociallink {
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: end;
   margin-bottom: 20px;
 }

 .sociallink h6 {
   font-size: 20px;
   color: #333;
   line-height: 30px;
   text-align: center;
   letter-spacing: 1.5px;
 }

 .sociallink:before {
   position: absolute;
   content: ' ';
   background: url('https://webapi.entab.info/api/image/SJHSMD/public/Images/socialbg.png') center bottom no-repeat;
   bottom: 0;
   left: 0;
   width: 100%;
   background-size: 100% 55%;
   height: 100%;
 }

 ul.socialMedia li {
   margin: 5px;

   background: #000;
   border-radius: 17px;
 }

 .homeeventboximg p {
   position: absolute;
   top: 10px;
   right: 9px;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
 }

 .homeeventboximg p img {
   width: 35px;
 }

 .homeeventboximg {
   position: relative;
 }

 .msginfo>div {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 10;
   overflow: hidden;
   margin-bottom: 20px;
 }

 .homeeventboximg p span {
   color: #fff;
   background: linear-gradient(180deg, #ff8800, 56%, #f0dd4d);
   display: flex;
   align-items: center;
   justify-content: end;
   font-size: 12px;
   padding: 10px 15px;
   border-radius: 21px;
   font-weight: bold;
 }

 .homeeventbox {
   overflow: hidden;
   background: #fff;
   padding: 10px;
   margin: 0px 10px 20px 10px;
   border-radius: 21px;
   box-shadow: 2px 20px 20px -18px #0000003d;

 }

 .homeeventbox .homeeventboxdesc {
   text-align: center;
   padding: 10px;
 }

 .homeeventboximg>img.img-fluid {
   height: 240px;
   width: 100%;
   object-fit: cover;
   border-radius: 20px;
 }

 .homeeventboxtitle h3 {
   text-transform: uppercase;
   font-size: 20px;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
   color: #0E5C83;
   font-family: "Open Sans", sans-serif;
   font-weight: bold;
 }

 .homeeventboxtitle {
   margin-top: 10px;
 }

 .homeeventboxtitle p {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
 }

 .homeeventboxbottom {
   background: linear-gradient(180deg, #ff8800, 56%, #f0dd4d);
   border-radius: 15px;
   color: #000;
   font-weight: 500;
   padding: 5px;
   text-transform: uppercase;
   font-size: 12px;
 }

 .overlay {
   position: absolute;
   width: 100%;
   height: 100%;
   background: #000000b0;
   display: flex;
   align-items: center;
   justify-content: center;
   opacity: 0;
   transition: 0.5s;
 }

 .homeeventbox:hover .overlay {
   opacity: 1;
 }

 .abt_abs.abs-img1 {
   left: 20px;
   top: 130px;
 }

 .abt_abs.abs-img2 {
   right: 30px;
   top: 130px;
 }

 .about-img {
   padding: 160px 0 90px;
   position: relative;
   overflow: hidden;
   margin-left: 50px;
 }

 .abt_abs.abs-img4 {
   left: 30px;
   bottom: 60px;
 }

 .abt_abs.abs-img5 {
   right: 30px;
   bottom: 60px;
 }

 .abt_abs img {
   border-radius: 50%;
   position: relative;
   z-index: 9;
   background: linear-gradient(359deg, #FBEF7B, 10%, #DFB320, 90%, #FBEF7B);
   padding: 5px;
 }

 .circle {
   position: absolute;
   color: white;
   text-align: center;
   border-radius: 50%;
 }

 .circle::after,
 .circle::before {
   content: "";
   display: block;
   position: absolute;
   top: 5px;
   left: 5px;
   width: 100px;
   height: 100px;
   background: linear-gradient(45deg, #fbef7b, #b58e0b);
   border-radius: 50%;
   z-index: 1;
   animation: grow 3s ease-in-out infinite;
 }

 .circle::after {
   background: linear-gradient(45deg, #b58e0b, #fbef7b);
 }

 .circle::after::before {
   content: "";
   display: block;
   position: absolute;
   top: 0;
   left: 0;
   width: 100px;
   height: 100px;
   background: #5f84ff;
   border-radius: 50%;
   z-index: -1;
   animation: grow 1s ease-in-out infinite;
 }

 .circle::before {
   background: linear-gradient(45deg, #fbef7b, #b58e0b);
   animation-delay: -0.5s;
 }

 @keyframes grow {
   0% {
     transform: scale(1, 1);
     opacity: 1;
   }

   100% {
     transform: scale(1.8, 1.8);
     opacity: 0;
   }
 }

 .event:before {
   content: "";
   width: 320px;
   height: 330px;
   background: linear-gradient(45deg, #f0dd4d, #ff8800);
   position: absolute;
   left: -220px;
   top: 100px;
   border-radius: 110px;
   transform: rotate(45deg);
   animation: 20s rotate infinite;
 }

 .event .maintitle>div h1 {
   position: relative;
   z-index: 1;
 }

 .event .maintitle>div h1:after {
   content: "";
   width: 320px;
   height: 330px;
   background: url('https://webapi.entab.info/api/image/SJHSMD/public/Images/shape.png') no-repeat;

   animation: 20s up infinite;
   position: absolute;
   left: -160px;
   top: -100px;
   z-index: -1;
 }

 @keyframes up {
   0% {
     transform: translateY(-50px)
   }

   60% {
     transform: translateY(0px)
   }

   100% {
     transform: translateY(-50px)
   }
 }

 @keyframes rotate {
   0% {
     transform: rotate(0deg)
   }

   100% {
     transform: rotate(360deg)
   }
 }

 .event:after {
   content: "";
   width: 320px;
   height: 330px;
   background: linear-gradient(45deg, #f0dd4d, #ff8800);
   position: absolute;
   left: -200px;
   top: 140px;
   border-radius: 110px;
   transform: rotate(45deg);
   opacity: 0.2;
   animation: 40s rotate infinite;
 }

 #table-body .col:nth-child(7n+1) {
   color: #FF7676;
   font-weight: bold;

 }

 .calender:before {
   content: "";
   width: 320px;
   height: 330px;
   background: linear-gradient(45deg, #f0dd4d, #ff8800);
   position: absolute;
   right: -220px;
   top: 0;
   border-radius: 110px;
   transform: rotate(45deg);
   animation: 20s rotate infinite;
 }

 .calanderhead,
 .schoolnotice .newsbg {
   position: relative;
 }

 .calanderhead .maintitle>a img {
   top: 0;
   right: 60px;
 }

 .schoolnotice .newsbg ul.newsboxlist li {
   border-bottom: 0px;
 }

 .schoolnotice .newsbg .maintitle>a img {
   top: 30px;
   right: 0px;
 }

 .schoolnotice .newsbg marquee {
   height: 310px;
   margin-bottom: 70px;
 }

 @keyframes rotate {
   0% {
     transform: rotate(0deg)
   }

   100% {
     transform: rotate(360deg)
   }
 }

 .calender:after {
   content: "";
   width: 320px;
   height: 330px;
   background: linear-gradient(45deg, #f0dd4d, #ff8800);
   position: absolute;
   right: -200px;
   top: -50px;
   border-radius: 110px;
   transform: rotate(45deg);
   opacity: 0.2;
   animation: 40s rotate infinite;
 }

 .about-news p {
   line-height: 25px;
   font-size: 15px;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 15;
   overflow: hidden;
 }

 .about-news h2 span {
   -webkit-text-stroke: 1px #000;
   color: #0000;
 }

 .about-news button.button {
   position: relative;
   bottom: -17px;
   left: 50%;
   transform: translate(-50%);
   padding: 11px 20px;
   border-radius: 10px;
 }

 .slider-sec {
   position: relative;
 }

 .copyright {
   text-align: center;
   font-size: 13px;
   padding: 10px;
 }

 .newsbg {
   color: #fff;
   width: 100%;
   margin: auto;
   padding: 0px 20px;
 }

 .newsbg li {
   list-style: none;
   margin-bottom: 13px;
 }

 .newsbg ul.newsboxlist .newsbox {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   margin-bottom: 5px;
   margin-top: 20px;

 }

 .newsbg .title {
   display: flex;
   align-items: center;
 }

 .newsbg button.button.light {
   position: absolute;
   right: 0;
   width: max-content;
   min-width: max-content;
   border-radius: 6px 0 0 6px;
 }

 .newsbg ul.newsboxlist .newsbox a {
   background: var(--main-gradient);
   color: #000;
   width: 30px;
   height: 30px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 5px;
 }

 .newsdesc {
   width: 90%;
 }

 .newsdesc h6 {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
   color: #333;
 }

 .newsbg ul.newsboxlist .newsbox p {
   margin-bottom: 0;
   color: #333;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   overflow: hidden;
   font-family: "Open Sans", sans-serif;
   font-size: 16px;
   font-weight: 400;
 }

 .padding-0 {
   padding: 0;
 }

 .newsbg ul.newsboxlist li {
   position: relative;
   border-radius: 0;
   padding-left: 30px;
   border-bottom: 1px dashed #999;
   margin-bottom: 15px;
   padding-bottom: 13px;
 }

 .newsbg .title a {
   position: absolute;
   right: 0;
 }

 .newsbg marquee {
   height: 420px;
   margin-top: 30px;
 }

 ul.newsboxlist {
   padding-left: 0;
 }

 .newsbg ul.newsboxlist li:after {
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/li.png);
   content: ' ';
   position: absolute;
   width: 100px;
   height: 100px;
   background-repeat: no-repeat;
   top: 0;
   left: 0px;
 }

 .aboutnews {
   width: 100%;
   z-index: 2;
   margin: auto;
   position: relative;
   padding: 50px 0;
   height: 100%;
 }

 .actibox {
   background: url(https://webapi.entab.info/api/image/SJHSMD/public/Images/actibg.png);
   border-radius: 16px;
   margin-left: auto;
   padding: 40px 40px 60px 40px;
   position: relative;
   width: 93%;
   background-size: 100% 100%;
 }

 .actibox .maintitle>a img {
   top: 0;
   right: 33px;
   z-index: 2;
 }

 .activites {
   background: #fff;
   padding: 10px;
   margin: 0px 10px 20px 10px;
   border-radius: 20px;
   box-shadow: 0px 0px 5px 1px #ccc;
 }

 .calender .maintitle {
   margin-right: 100px;
 }

 .activitesbox img {
   height: 250px;
   width: 100%;
   object-fit: cover;
   border-radius: 20px;
   transition: all 0.3s ease-in;
 }

 .activitesbox {
   position: relative;
 }

 .activites .gallerydesc h6 {
   font-size: 20px;
   line-height: 30px;
   font-family: "Open Sans", sans-serif;
   color: #333;
   text-transform: uppercase;
   text-align: center;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
   padding-top: 10px;
 }

 .activitesbox a.gallery_button {
   position: absolute;
   top: 50%;
   left: 50%;
   transition: all 0.3s ease-in;
   transform: translate(-50%, -50%);
   opacity: 0;
 }

 .activites:hover .activitesbox img {
   filter: grayscale(1);
 }

 .activites:hover .activitesbox a.gallery_button {
   opacity: 1;
 }

 .maintitle p {
   -webkit-text-stroke: 1px #000;
   color: #0000;
   font-size: 40px;
   margin-bottom: 0;
   text-transform: uppercase;
   font-family: "Bebas Neue", sans-serif;
   margin-left: 40px;
 }

 .maintitle>div {
   align-items: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
 }

 .maintitle h1 {
   color: #0E5C83;
   font-size: 40px;
   margin-bottom: 0;
   line-height: 10px;
 }

 .maintitle>div p img {
   width: 130px;
   position: relative;
   top: 0;
   margin-top: -130px;
   margin-right: -130px;
 }

 .quick-link h3 {
   color: #333;
 }

 .quick-link {
   margin-left: -20px;
 }

 .innerslide {
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: center;
   background: linear-gradient(360deg, #1c1b3b8f 100%, #1c1b3b91 100%, #3333338c 100%), url('https://webapi.entab.info/api/image/SJHSMD/public/Images/innersld.jpg') no-repeat center center;
   background-size: cover;
   height: 255px;
   padding-bottom: 50px;
 }

 .innersec {
   min-height: 400px;
   padding: 60px 10px 80px 10px;
 }

 .innersec p {
   color: #4e4e4e;
   font-family: Roboto Slab, serif;
   font-size: 16px;
   line-height: 34px;
 }

 .innersec h1 {
   z-index: -1;
   font-size: 35px;
   position: relative;
   padding-left: 70px;
   background: -webkit-linear-gradient(#c30c0c, #1278ac);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   line-height: 45px;
   padding-bottom: 20px;
 }

 .innersec h1:after {
   position: absolute;
   content: ' ';
   width: 60px;
   height: 3px;
   background: -webkit-linear-gradient(#c30c0c, #1278ac);
   top: 20px;
   bottom: 0;
   left: 0;
 }

 .breadcrumb li:before {
   position: absolute;
   content: ' \F138 ';
   width: 100%;
   height: 100%;
   font-family: bootstrap-icons;
   left: -15px;
 }

 .breadcrumb li:first-child:before {
   content: "\f404";
   background: #3c9ccf;
   width: 30px;
   border-radius: 20px;
   height: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
   left: -25px;
   top: -3px;
 }

 .breadcrumb li a {
   color: #f9f9f9;
   position: relative;
 }

 .breadcrumb li {
   padding: 0px 15px 0px 15px;
   color: #fff;
   position: relative;
   margin-left: 7px;
   font-size: 14px;
 }

 ul.breadcrumb {
   margin-bottom: 0;
 }

 .navbar-nav .dropdown-menu li {
   padding: 0px 0px !important;
   font-size: 15px !important;
   text-transform: capitalize !important;
   border-bottom: 1px solid #990d0d2e;
 }

 .navbar-nav .dropdown-menu {
   padding: 0;
   margin: 0;
   border-radius: 4px;
   border: 0px;
 }

 .left-img {
   border: 10px solid #fff;
   box-shadow: 1px 5px 8px 0px #ccc;
   border-radius: 4px;
   margin-left: 20px;
   margin-bottom: 20px;
 }

 .maintable thead th {
   border: 1px solid #ccc;
   background: #016fa8;
   color: #fff;
   vertical-align: middle;
 }

 .maintable tbody td {
   border: 1px solid #ccc;
 }

 .maintable tbody tr:nth-child(odd) {
   background: #f1f1f145;
 }

 .maintable tbody tr:nth-child(even) {
   background: #016fa829;
 }

 .magnify-modal {
   position: fixed !important;
   z-index: 999999999 !important;
   padding-top: 10px !important;
   left: 0 !important;
   top: 0 !important;
   width: 100% !important;
   height: 100% !important;
   overflow: auto !important;
   background-color: rgba(0, 0, 0, 0.8509803922) !important
 }

 .magnify-header .magnify-toolbar {
   float: right;
   position: relative;
   z-index: 9
 }

 .magnify-stage {
   position: absolute;
   top: 40px;
   right: 10px;
   bottom: 40px;
   left: 10px;
   z-index: 1;
   background: #0000000a;
   overflow: hidden
 }

 .magnify-image {
   position: relative;
   display: inline-block;
   border: 5px solid #fff
 }

 .magnify-image.image-ready {
   max-width: 100%;
   max-height: 100%
 }

 .magnify-footer {
   position: absolute;
   bottom: 0;
   z-index: 2;
   width: 100%;
   height: 40px;
   color: #fff;
   text-align: center
 }

 .magnify-footer .magnify-toolbar {
   display: inline-block
 }

 .magnify-button {
   display: inline-block;
   width: 40px;
   height: 40px;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
   margin: 0;
   padding: 10px;
   font-size: 16px;
   color: #fff;
   text-align: center;
   border-width: 0;
   border-radius: 0;
   cursor: pointer;
   outline: none;
   background: #055a91;
 }

 .magnify-button:hover {
   color: #fff;
   background-color: #e00000
 }

 .magnify-button-close:hover {
   background-color: #e00000
 }

 .magnify-loader {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 2;
   text-align: center;
   background-color: rgba(0, 0, 0, 0.3);
   color: #333
 }

 .magnify-loader::before {
   content: '';
   display: inline-block;
   position: relative;
   width: 36px;
   height: 36px;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
   border-width: 5px;
   border-style: solid;
   border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
   border-radius: 100%;
   -webkit-animation: magnifyLoading 1s infinite linear;
   animation: magnifyLoading 1s infinite linear
 }

 .magnify-loader::after {
   content: '';
   display: inline-block;
   width: 0;
   height: 100%;
   vertical-align: middle;
   overflow: hidden
 }

 @-webkit-keyframes magnifyLoading {
   0% {
     -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
     transform: rotateZ(0deg) translate3d(0, 0, 0)
   }

   100% {
     -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
     transform: rotateZ(360deg) translate3d(0, 0, 0)
   }
 }

 @keyframes magnifyLoading {
   0% {
     -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
     transform: rotateZ(0deg) translate3d(0, 0, 0)
   }

   100% {
     -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
     transform: rotateZ(360deg) translate3d(0, 0, 0)
   }
 }

 .smartclass {
   padding: 5px;
   margin-bottom: 20px;
   border: 5px double #ca40406b;
   border-radius: 5px;
 }

 .infraimg {
   border: 4px double #e2b9cb;
   border-radius: 3px;
   width: 100%;
 }

 .infrastructuredesc {
   text-align: center;
   margin-bottom: 15px;
   padding-top: 5px;
   font-size: 14px;
   color: #333;
   font-weight: bold;
 }

 .sample-blk {
   display: inline-flex;
   flex-direction: row-reverse;
   padding: 10px 15px;
   justify-content: flex-end;
   align-items: center;
   position: relative;
   overflow: hidden;
   height: 100px;
   margin-bottom: 15px;
   width: 100%;
   box-shadow: 0px 2px 6px 1px #ccc;
   border-radius: 3px;
   border: 1px dashed #464777;
 }

 .sample-blk p {
   line-height: 30px;
   text-decoration: none !important;
   margin-bottom: 0;
   overflow: hidden;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
 }

 .sample-blk img {
   max-width: 70px !important;
   max-height: 70px !important;
   margin-bottom: 0;
   margin-right: 15px;
 }

 .sample a {
   text-decoration: none;
 }

 .sample-blk span {
   font-size: 12px;
 }

 .sample-blk:after {
   content: "";
   position: absolute;
   height: 100px;
   width: 175px;
   top: 17px;
   border-bottom-left-radius: 50%;
   right: -70px;
   background: linear-gradient(45deg, #f5f5ff, #ffffff);
   z-index: -1;
   transform: rotate(132deg);
 }

 .contactdiv {
   width: 65%;
 }

 .enquiryformimg {
   width: 35%;
   background: #307a961f;
   padding-left: 20px;
   padding-right: 20px;
 }

 .enquiryform {
   flex-wrap: wrap;
   display: flex;
   flex-direction: row;
   justify-content: center;
   width: 100%;
   background: #fff;
 }

 .locationbox {
   margin-top: 65px;
 }

 .locationicon {
   padding: 0px 7px;
   width: 45px;
 }

 .locationicon i {
   color: #3ba3d8;
   font-size: 30px;
 }

 .locationdiv {
   display: flex;
   margin-bottom: 20px;
   border-bottom: 1px dashed #3ba3d8;
 }

 .locationdetails {
   margin-left: 5px;
   width: 90%;
   margin-bottom: 10px;
 }

 .locationdiv h5 {
   color: #3ba3d8;
   font-size: 19px;
   text-transform: capitalize;
 }

 .locationdiv p a {
   color: #000;
   font-size: 16px;
   margin-bottom: 0;
 }

 .locationdiv:last-child {
   border-bottom: 0px dashed #3ba3d8;
 }

 #myImg,
 #myImg1,
 #myImg2 {
   width: 100%;
   border: 5px solid rgba(158, 158, 158, 0.1);
   background: #eaeaea;
   cursor: pointer;
   transition: 0.3s;
 }

 .erp_sec img {
   display: inline-block;
 }

 .erp_sec h4.imgheading {
   text-align: center;
 }

 .assistance {
   background: #eaeeff;
   padding: 20px;
   margin-top: 30px;
 }

 .erp_sec h4 {
   text-align: center;
   line-height: 40px;
   font-size: 17px;
   margin-bottom: 0;
   margin-top: 15px;
   font-weight: 700;
   font-family: 'Roboto', sans-serif;
 }

 .assistance {
   background: rgb(230 243 255);
   padding: 13px;
   border: solid 1px #ddd;
   margin-top: 20px;
 }

 .erpbox {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   flex-wrap: wrap;
   width: 100%;
 }

 .erpboxdesc {
   width: 72%;
 }

 .erpboximg {
   width: 26%;
 }

 .erpboxdescpopup {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
 }

 .modal {
   background: #0000008a;
   z-index: 99999;
   padding-right: 0 !important;
 }

 .erppopup {
   width: 30%;
   margin: 10px;
 }

 .imgheading a {
   text-align: center;
   width: 100%;
   display: block;
   font-size: 20px;
   padding: 10px 0;
 }

 .erppopup .modal-body h5 {
   color: #000;
   text-align: center;
   padding-top: 20px;
 }

 .chooseyear {
   width: max-content;
   float: right;
   margin-bottom: 30px;
   margin-top: -45px;
 }

 #chooseddlYear option {
   color: black;
 }

 .chooseyear select {
   margin: 0px;
 }

 .chooseyear select {
   width: 100%;
   background: unset;
   border: unset;
   height: 28px;
   margin-top: 15px;
   border: unset;
   border-bottom: 1px solid grey;
 }

 .clr {
   clear: both
 }

 .video-gallery {
   padding: 20px;
   box-shadow: 0px 1px 4px 1px #03030330;
   margin-bottom: 20px;
   background: #3ba3d826;
   border-radius: 3px;
 }

 .video-gallery a {
   display: flex;
   color: inherit;
   align-items: center;
 }

 .video-gallery a i {
   font-size: 40px;
   color: #ad0807;
   margin-right: 10px;
   display: flex;
 }

 .video-gallery a h3 {
   font-size: 16px;
   margin-bottom: 0;
   line-height: 24px;
   padding-top: 0;
   padding-bottom: 0;
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   margin-top: 0;
   padding-left: 0;
 }

 .video-gallery p {
   font-size: 12px;
   margin-bottom: 0;
 }

 .video-gallery a h3:after {
   position: static;
 }

 .video-gallery a img {
   width: 52px;
   margin-right: 10px;
 }

 .row.tabs-dynamic {
   background: #3ba3d826;
   padding: 20px;
   margin: 0px 0px 40px 0px;
 }

 .row.tabs-dynamic p {
   margin-bottom: 0;
 }

 .row.tabs-dynamic p a {
   color: #585757;
 }

 .row.tabs-dynamic select {
   width: 100%;
   border: 0px;
   background: transparent;
   padding: 8px 5px;
   border-bottom: 1px solid #b6b6b6;
 }

 .row.tabs-dynamic input {
   padding: 9px 5px;
   width: 100%;
   border: 0px;
   border-bottom: 1px solid #b6b6b6;
   background: transparent;
 }

 .count-val p {
   margin-bottom: 0px;
 }

 .gallery-inner {
   background: linear-gradient(88deg, #edde78, #8ad6fc);
   margin-bottom: 30px;
   box-shadow: 7px 6px 12px -4px #ddd;
   /* position: relative; */
   overflow: hidden;
   transition: all 0.5s ease-in;
 }

 .gallery-blk a {
   color: inherit;
 }

 .gallery-content {
   padding: 20px 15px;
   position: relative;
   background: #fff;
   width: 96%;
   margin: -30px auto 10px auto;
 }

 .gallery-blk img {
   width: auto;
   max-width: 100%;
   height: 90%;
   margin: auto;
   display: block;
   position: relative;
   box-shadow: 0px 0px 19px 0px #00000054;
   border: 7px solid #ffffff4f;
 }

 .gallery-inner:hover .gallery-content {
   bottom: 0;
 }

 .gallery-blk {
   height: 230px;
   object-fit: contain;
   position: relative;
   background: #ddd;
   overflow: hidden;
   transition: 0.5s;
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .sub-gallerycount .gallery-blk img {
   height: 100%;
 }

 .sub-gallerycount .gallery-blk {
   height: 200px;
   margin-bottom: 20px;
 }

 .gallery-blk .gallery-title h3 {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
 }

 .gallery-title h3 {
   font-size: 18px;
   color: #978a5a;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
   padding: 0px;
   margin-bottom: 15px;

 }

 a.view-more {
   color: var(--black);
   font-size: 14px;

 }

 .gallery-title p {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
   line-height: 28px !important;
   font-size: 16px !important;
   text-align: left;
   margin-top: 10px;
   margin-bottom: 10px;
 }

 .gallery-date {
   display: flex;
 }

 .gallery-date h6 {
   color: #fff;
   font-size: 16px;
   margin-bottom: 10px;
   line-height: 25px;
   background: #b72c2c;
   padding: 5px 10px;
   border-radius: 5px;

 }

 .gall-num h5 {
   background: transparent;
   color: #000;
   font-size: 14px;
   margin-bottom: 10px;
   line-height: 25px;
   padding: 5px 10px;
   border-radius: 5px;

 }

 .gallery-title {
   font-size: 20px;
   line-height: 30px;

 }

 .sub-gallerycount img {
   height: 200px;
   width: 100%;
   object-fit: cover;
   background: #f1f1;
   box-shadow: 0px 0px 3px 1px #0000004a;
 }

 .sub-gallerycount {
   margin-bottom: 20px;
 }

 .news-head {
   display: flex;
   align-items: center;
 }

 .news-head h2 {
   padding-right: 20px;
   border-right: 1px solid #c5c5c5;
   margin-right: 20px;
   background: #e5e5e5;
   padding: 4px 10px;
 }

 .news-head h3 {
   font-weight: 400;
   font-size: 22px;
   margin-top: 0;
   margin-bottom: 14px;
   color: #00599b;
   position: relative;
 }

 .news-blk {
   margin-bottom: 20px;
   padding-right: 30px;
   padding-bottom: 30px;
   width: 97%;
   box-shadow: 9px 11px 5px 0px #dddddd3d;
   position: relative;
   overflow: hidden;
   padding: 20px 0px 20px 20px;
   box-shadow: 0px 0px 12px 0px #e7e7e7;
   border: 4px double #b5b5b5;
   transition: 0.5s;
 }

 .news-blk:before {
   content: "";
   position: absolute;
   top: -40px;
   right: -8px;
   width: 100px;
   height: 100px;
   background: #ecf9ff;
   border-radius: 50%;
 }

 .attachment {
   background: #c30c0c;
   text-align: right;
   position: absolute;
   right: 0;
   bottom: 0;
   color: #fff;
   padding: 5px;
 }

 .news-content p {
   margin-bottom: 6px;
   max-height: 160px;
   overflow-y: scroll;
 }

 .news-content p::-webkit-scrollbar {
   width: 0;
 }

 .news-blk:hover {
   box-shadow: rgb(0 0 0 / 5%) 0px 2px 1px, rgb(0 0 0 / 5%) 0px 4px 2px, rgb(0 0 0 / 5%) 0px 8px 4px, rgb(0 0 0 / 5%) 0px 16px 8px, rgb(0 0 0 / 5%) 0px 32px 16px;
 }

 .news-head h2 span {
   display: block;
   color: #153168;
   font-size: 18px;
   text-align: center;
 }

 .newsCount:nth-child(4n+1)>div {
   border-left: 3px solid #fff27e;
 }

 .newsCount:nth-child(4n+2)>div {
   border-left: 3px solid #ceb2ff;
 }

 .newsCount:nth-child(4n+3)>div {
   border-left: 3px solid #ff928f;
 }

 .newsCount:nth-child(4n+4)>div {
   border-left: 3px solid #ffb470;
 }

 .eventsnewsbox {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   background: #fff;
   box-shadow: 2px 0px 8px 0px #ccc;
   margin-bottom: 20px;
 }

 .newsboximg {
   width: 40%;
   margin-right: 10px;
 }

 .newsboxdesc {
   width: 58%;
 }

 .newspgh {
   height: 150px;
   overflow-y: scroll;
   padding-top: 15px;
   margin-top: 10px;
   padding-right: 10px;
 }

 .uniformimg {
   height: auto;
   margin-bottom: 20px;
 }

 .newsboximg img {
   border: 10px solid #fff;
   height: 190px;
   object-fit: contain;
   background: #f1f1f1;
   width: 100%;
 }

 .newsdate {
   display: flex;
   justify-content: space-between;
   padding: 8px 5px;
 }

 .newspgh p {
   font-size: 15px;
   line-height: 30px;
 }

 .newsdate a {
   background: #c30c0c;
   border-radius: 5px 3px 6px 0px;
   color: #fff;
   padding: 2px 5px;
 }

 .newsdate span {
   font-size: 14px;
   line-height: 30px;
 }

 .event-logo {
   height: 320px !important;
 }

 i {
   position: relative;
   top: -2px;
 }

 .newspgh h5 {

   color: #000000ad;
   line-height: 30px;
   font-size: 18px;
 }

 .newspgh::-webkit-scrollbar {
   width: 6px;
   background-color: #F5F5F5;
 }

 .newspgh::-webkit-scrollbar-thumb {
   background-color: var(--maroon);
 }

 .newspgh::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
   background-color: #F5F5F5;
 }

 .gallery-blks img {
   height: 230px;
   width: 100%;
   object-fit: cover;
 }

 .toppers-inner {
   min-height: 300px;
   padding: 10px;
   margin-bottom: 20px;
   background: #42437024;
   border-radius: 3px;
   box-shadow: inset 0px 0px 13px 5px #3d3e6857;
 }

 .toppers-inner img {
   margin: auto;
   width: 150px;
   height: 140px;
   border-radius: 3px;
   margin-top: 20px;
   display: block;
   border: 6px solid #fff;
 }

 .toppers-inner h3 {
   text-align: center;
   font-size: 15px;
   line-height: 25px;
   margin: 0px;
   margin-top: 10px;
 }

 .toppers-inner p {
   text-align: center;
   margin-bottom: 0;
   line-height: 30px;
 }

 .toppers-inner h4 {
   font-size: 15px;
   text-align: center;
 }

 .toppers-inner h5 {
   margin: 0px;
   color: #ac2121;
   font-size: 15px;
   text-align: center;
   line-height: 25px;
 }

 .popup {
   padding-right: 0 !important;
 }

 .popup .carousel-control-prev-icon {
   background: url('https://webapi.entab.info/api/image/SJHSMD/public/Images/lefticn.png') no-repeat !important;
 }

 .popup .carousel-control-next-icon {
   background: url('https://webapi.entab.info/api/image/SJHSMD/public/Images/righticn.png') no-repeat !important;
 }

 .popup .carousel-control-next,
 .popup .carousel-control-prev {
   width: auto;
 }

 .popup .carousel-control-next {
   right: -15px;
 }

 .popup .modal-dialog {
   min-width: 40%;
   transform: translateY(-100px);
 }

 .carousel-item .modal-header {
   background: #0a4685;
   padding: 13px 0 0
 }

 .popup .modal-dialog img {
   width: 100%;
   object-fit: contain;
 }

 .carousel-item .modal-body h1 {
   padding-bottom: 0;
   text-align: center;
   font-size: 17px;
   color: #000;
   overflow: hidden;
   font-weight: 600;
   text-transform: capitalize;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   margin-bottom: 10px;
   line-height: 28px;
 }

 .carousel-item .modal-dialog .modal-header .btn-close {
   color: #fff;
   padding-right: 31px
 }

 .carousel-item .modalcard .card-body {
   padding: 10px
 }

 .carousel-item .modalcard .card-body p {
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   line-height: 35px;
   text-align: center;
   padding-top: 10px;
   line-height: 26px;
 }

 .carousel-item .modalcard {
   border: none
 }

 .popup .modal-content {
   padding: 0 0 10px
 }

 .carousel-item button.btn-close:focus {
   box-shadow: none
 }

 .carousel-item a.btn.btn-primary.btn-sm {
   border-radius: 6px;
   box-shadow: -2px 14px 11px -9px #0000008a;
   transition: .5s;
   background: #8c0000;
   border: #8c0000;
   margin: 0 10px;
   color: #fff !important;
   padding: 10px;
   font-size: 14px;
   text-transform: uppercase;
   font-weight: 600;
 }

 .carousel-item a.btn.btn-primary.btn-sm:focus {
   box-shadow: none;
   border: 1px solid #8c0000;
 }

 .carousel-item a.btn.btn-primary.btn-sm:hover {
   background: linear-gradient(0deg, #8c0000, #f56c6c);
 }

 .carousel-item button.btn-close {
   position: absolute;
   z-index: 999;
   background-color: #8c0000;
   right: 0;
 }

 .carousel-item .btn-close {
   background: url('https://webapi.entab.info/api/image/SJHSMD/public/Images/closeicn.png') no-repeat;
   opacity: 1;
   padding: 10px;
   background-position: center;
   border-radius: 0;
 }

 .modal-bottom {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin-top: 30px;
 }

 .galleryimgbox img {
   height: 250px;
   width: 100%;
 }

 span.bounce1 img {
   width: 22px;
 }

 .OnlineR a {
   color: #fff;
 }

 a.parantlogo {
   padding: 2px 10px;
 }

 .OnlineR {
   position: fixed;
   right: 0;
   z-index: 9999;
   text-align: center;
   color: #fff;
   top: 45%;
   font-family: Open Sans, sans-serif;
 }

 @keyframes bounce1 {
   0% {
     background-color: #881525;
   }

   33% {
     background-color: #ecb30b;
   }

   66% {
     background-color: #881525;
   }

   100% {
     background-color: #ecb30b;
   }
 }

 .bounce1 {
   border-radius: 25px 0px 0px 25px;
   color: #fff;
   font-size: 14px;
   font-weight: 700;
   text-align: center;
   animation: bounce1 1s infinite;
   padding: 10px 12px;
 }

 .tcblock {
   flex-wrap: wrap;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   width: 100%;
 }

 .tc {
   width: 49%;
   margin-bottom: 30px;
 }

 .tcblock iframe {
   width: 49%;
 }

 .tc label {
   color: #000;
   width: 100%;
   margin-bottom: 0;
 }

 .tc label p {
   margin-bottom: 0px;
 }

 .tc .form-group {
   margin-bottom: 20px;
   width: 100%;
 }

 .form-control:focus {
   box-shadow: none;
   border: 1px solid #ccc;
 }

 a.viewdetailsbtn {
   color: #fff;
   font-size: 14px;
 }

 .viewdetailsbtn {
   padding: 10px 15px !important;
   text-decoration: none;
   color: #fff !important;
   border-radius: 5px !important;
   margin-bottom: 20px;
   border: none !important;
   background: linear-gradient(131deg, #9c1010, #38395f) !important;
   transition: all 0.2s ease-in;
   box-shadow: none !important;
 }

 .viewdetailsbtn:hover {
   background: linear-gradient(131deg, #40416d, #950909);
 }

 ul.list li {
   color: #4e4e4e;
   font-size: 16px;
   line-height: 34px;
   list-style: none;
   margin-right: 35px;
   position: relative;
 }

 ul.list li:after {
   color: #2196d2;
   content: "\2726";
   font-size: 1.6em;
   left: -30px;
   position: absolute;
   top: 0;
 }

 .innersec h1,
 .innersec h2,
 .innersec h3,
 .innersec h4,
 .innersec h5,
 .innersec h6 {
   font-family: Roboto Slab, serif;
 }

 .mesdeskimg h3 {
   color: #333;
   font-weight: 700;
   font-size: 16px;
   line-height: 34px;
 }

 .mesdeskimg h5 {
   color: #b29a1c;
   display: block;
   font-weight: 700;
   font-family: Roboto Slab, serif;
   font-size: 16px;
   line-height: 34px;
 }

 .innersec h3 {
   font-size: 19px;
   line-height: 30px;
   color: #c30c0c;
   margin-bottom: 15px;
 }

 .homegallery .maintitle {
   position: relative;
 }

 form.formsec .form-group {
   margin-bottom: 15px;
 }

 form.formsec .form-group label {
   font-size: 15px;
   margin-bottom: 5px;
 }

 form.formsec input.form-control {
   box-shadow: inset 1px -1px 9px 2px #ccccccbf, 1px -1px 0px 2px #cccccc91;
   border: 0px;
 }

 input[type="submit"] {
   border: 0px solid #fffad8;
   padding: 8px 20px;
   background: #fff;
   border-radius: 5px;
   margin-top: 20px;
   color: #2196d2;
   box-shadow: inset 1px -1px 9px 2px #ccccccbf, 1px -1px 0px 2px #cccccc91;
   font-weight: bold;
 }

 a.frmbtn {
   border: 0px solid #fffad8;
   padding: 8px 20px;
   background: #197fb2;
   border-radius: 5px;
   margin-top: 20px;
   color: #fff;
   box-shadow: 1px -1px 9px 2px #ccccccbf, 1px -1px 0px 2px #cccccc91;
   font-weight: bold;
   margin-left: 10px;
 }

 form.formsec {
   background: #197bae1f;
   padding: 30px;
   border-radius: 4px;
   margin-bottom: 30px;
 }

 .kids {
   background: url('https://webapi.entab.info/api/image/SJHSMD/public/Images/kids-bg.jpg') fixed repeat;
   background-size: contain;
   width: 100%;
   padding-top: 100px;
   padding-bottom: 100px;
 }

 .kids-back-sec {
   background: #ffffffeb;
   border-radius: 40px;
   min-height: 800px;
   padding: 20px;
 }

 .details a {
   color: #146c99;
 }

 .kidslogo {
   position: absolute;
   right: 0;
   top: 50px;
   background: #fff;
   border-radius: 10px;
   z-index: 1;
 }

 .kidslogo img {
   width: 110px;
 }

 .kids-back-sec h5,
 .kids-back-sec h6,
 .kids-back-sec h3 {
   font-family: Roboto Slab, serif;
 }

 p.error {
   font-size: 12px;
   margin-top: 6px;
   color: #c9230c;
 }

 @media(max-width: 1600px) {
   .nav-link {
     font-size: 15px;
   }

   .quick-link ul li {
     font-size: 13px;
   }

   .address span {
     font-size: 13px;
   }

   .padded-sec {
     padding-left: 70px !important;
     padding-right: 70px !important;
   }
 }

 @media(max-width: 1470px) {
   .newsbg marquee {
     height: 160px;
   }

   .homeeventboximg>img.img-fluid {
     height: 200px;
   }

   .aboutmidsec {
     padding: 0 10px;
   }
 }

 @media(max-width: 1400px) {
   .slidersecfst:before {
     height: 120px;
   }

   .slidersecfst {
     width: 35%;
   }

   .birthdaybg .toppertaj {
     width: 170px;
     height: 155px;
   }

   div#table-header {
     display: flex;
     flex-wrap: inherit;
   }

   .about-img {
     margin-left: 0;
     padding: 220px 0 90px;
   }

   .activitesbox img {
     height: 200px;
   }

   .logo {
     width: 25%;
   }

   .header-nav {
     width: 73%;
   }

   .quick-link ul li {
     font-size: 12px;
   }

   .address span {
     font-size: 12px;
   }

   .ftr-logo img {
     width: 100%;
   }

   .footer h3 {
     font-size: 20px;
     line-height: 33px;
   }

   .form-group {
     width: 48%;
     margin-right: 13px;
   }

   .highlights,
   nav.navbar {
     width: 98%;
   }

   .nav-link {
     font-size: 14px;
   }

   .about-news {
     width: 90%;
     padding: 20px 20px 0;
   }

   nav.navbar ul.navbar-nav li {
     font-size: 18px;
   }

   .title h2 {
     font-size: 25px;
   }

   .padded-sec {
     padding-left: 30px !important;
     padding-right: 30px !important
   }

   .message-container .messagebox {
     width: 76%;
     background-size: 100% 100%;
     margin-right: 20px;
   }

   .aboutmidsec .button {
     width: 15%;
   }

   .aboutmidsec p {
     margin-bottom: 0;
     font-size: 14px;
   }
 }

 @media(max-width: 1200px) {
   .slidersecfst:before {
     background: none;
   }

   .header:before {
     height: 194px;
   }

   .enquiryformimg {
     width: 40%;
   }

   .contactdiv {
     width: 60%;
   }

   div#table-header .col {
     padding: 0;
     font-size: 14px;
   }

   .actibox {
     width: 100%;
   }

   .maintitle p,
   .maintitle h1 {
     font-size: 35px;
   }

   .schoolnotice .newsbg {
     width: 100%;
   }

   .calanderhead .maintitle>a img {
     right: 0;
   }

   .sociallink:before {
     background: none;
   }

   .maintitle p {
     margin-left: 0;
   }

   .slidersecfst {
     width: 26%;
   }

   .aboutsec {
     margin-top: 0;
   }

   .message-container .messagebox {
     width: 90%;
   }

   .ftr-logo img {
     width: 70%;
     margin-left: 0;
   }

   .newsboximg {
     width: 100%;
   }

   .newsboxdesc {
     padding-left: 10px;
     width: 100%;
   }

   .aboutmidsec .phone {
     display: none;
   }

   .aboutmidsec .button {
     width: 30%;
     margin: 5px;
   }

   .highlights {
     padding-top: 5px;
   }

   .footer {
     background-size: auto 100%;
     padding: 100px 80px 10px;
   }

   .header .logo {
     width: 100%;
   }

   .header {
     flex-wrap: wrap;
     height: auto;
   }

   .header .logo img {
     margin: auto;
     display: block;
   }

   .header-nav {
     width: 100%;
   }

   .highlights {
     width: 100%;
   }

   nav.navbar {
     width: 100%;
     margin: 0;
   }

   .aboutmidsec {
     flex-wrap: wrap;
   }
 }

 @media(max-width: 991px) {
   .toppertaj img {
     height: 130px;
   }

   .newsbg .title a {
     right: 0;
   }

   .slidersecfst:before {
     background: none;
   }

   .tc {
     width: 100%;
   }

   .tcblock iframe {
     width: 100%;
   }

   .contactdiv,
   .enquiryformimg {
     width: 100%;
   }

   .nav-link {
     color: #000 !important;
   }

   .schoolnotice .newsbg marquee {
     height: 220px;
   }

   .about-img {
     padding: 90px 0 90px;
   }

   .slidersec {
     flex-wrap: wrap;
   }

   .slidersecfst {
     width: 100%;
   }

   .slidersecfst:after {
     background: none;
   }

   .newsbg marquee {
     height: 260px;
   }

   div#main_nav ul {
     padding: 0px 15px;
   }

   nav.navbar {
     padding: 0px;
   }

   .aboutmidsec .button {
     width: 31%;
     margin: 5px;
   }

   .about-bg {
     background-size: 100% 100%;
     padding: 30px 0 60px 0px;
   }

   .tooper-birthday .slick-slider {
     margin-bottom: 40px;
   }

   .about-news {
     margin-top: 50px;
     margin-bottom: 60px;
   }

   .event {
     padding: 50px 0;
   }

   button.navbar-toggler {
     float: right;
     display: block;
     margin-left: auto;
     background: #fff;
   }

   nav.navbar {
     background: transparent;
   }

   div#main_nav {
     background: #fff;
     text-align: left;
   }

   div#main_nav ul {
     align-items: flex-start;
     position: absolute;
     background: #fff;
     border-radius: 15px;
   }

   div#main_nav ul li {
     padding: 5px 0;
     border-bottom: 1px dashed #ddd;
     width: 100%;
   }

   .calendar-wrapper,
   .message-container {
     flex-wrap: wrap;
     justify-content: center;
   }

   .message-container .messageimg {
     margin-top: 20px;
   }

   .message-container .messagebox {
     margin-right: 80px;
   }

   .messagebox.msgright {
     margin-right: 0 !important;
   }
 }

 @media(max-width: 767px) {
   .schoolnotice {
     padding: 10px 0px;
   }

   .topper_bg {
     padding-top: 10px;
   }

   .calender {
     padding: 10px 0px;
   }

   .message-container .messageimg .mainbtn.button {
     margin: 0px auto 15px auto;
   }

   .msgright .messageboximg {
     text-align: left;
   }

   .testimonialimg {
     margin: 0 auto;
     display: block;
   }

   .msgright .messagedesc {
     padding-left: 0;
   }

   .messagebox.msgright {
     margin-left: 0;
   }

   .messagedesc {
     padding-right: 0;
   }

   .message-container .messageimg {
     position: static;
   }

   .message-container .messagebox {
     margin-right: 0;
     padding: 30px;
   }

   .msgleft,
   .msgright {
     background: #f1f1f1;
   }

   .quick-link ul {
     height: auto;
   }

   .quick-link {
     margin-left: 0px;
   }

   .message-container .messageimg img {
     margin-left: 0;
   }

   ul.list li {
     width: 100%;
   }

   .form-group {
     width: 100%;
   }

   .newspgh {
     padding-left: 15px;
   }

   .newsboxdesc {
     width: 100%;
   }

   .newsboximg {
     width: 100%;
   }

   .left-img {
     margin-left: 0;
   }

   .aboutmidsec .button {
     width: 48%;
   }

   .event:before,
   .event:after {
     width: 240px;
     height: 240px;
     left: -203px;
   }

   .message-container {
     position: relative;
   }

   .calender:after,
   .calender:before {
     width: 240px;
     height: 240px;
   }

   .padded-sec {
     padding-left: 10px !important;
     padding-right: 10px !important;
   }
 }

 @media(max-width: 600px) {
   .schoolnotice {
     background-size: cover;
   }

   .about-bg {
     background-size: cover;
   }

   .about-news {
     margin-top: 0;
     border-radius: 5px;
     margin-bottom: 10px;
   }

   .maintitle>a img {
     position: static;
     margin: 0 auto;
   }

   .maintitle {
     width: 100%;
     margin: 0 auto;
     text-align: center;
   }

   .actibox {
     padding: 40px 10px 60px 10px;
   }

   .toppertaj {
     display: block;
     margin: 0 auto;
   }

   .aboutmidsec {
     align-items: center;
     justify-content: center;
     float: unset;
     margin-top: 0;
   }

   .about-img {
     padding: 10px 0 10px;
   }


   .calender_block {
     padding-bottom: 50px;
   }

   #calendar-table {
     text-align: center;
     font-size: 12px;
   }

   .center-img img {
     padding: 59px;
   }

   .center-img:after {
     transform: none;
   }

   .center-img {
     width: 80%;
   }

   .abt_abs {
     display: none;
   }

   .calendar-wrapper {
     width: 100%;
   }

   .aboutmidsec .button {
     width: 100%;
   }
 }

 @media(max-width: 380px) {
   form.formsec {
     padding: 30px 20px;
   }

   a.frmbtn {
     margin-top: 15px;
     margin-left: 4px;
   }

   input[type="submit"] {
     margin-left: 4px;
   }
 }